import React, {useEffect} from "react";
import { IconChevronDown } from '@tabler/icons-react';

export default function SearchBarTypeSelector({value = '', onChange}) {

    const [selectedItem, setSelectedItem] = React.useState(value ? value : 'all');
    const [dropdownVisible, setDropdownVisible] = React.useState(false);

    const options = {
        'all'
        : 'All types',
        'american_restaurant bakery bar barbecue_restaurant brazilian_restaurant breakfast_restaurant brunch_restaurant cafe chinese_restaurant coffee_shop fast_food_restaurant french_restaurant greek_restaurant hamburger_restaurant ice_cream_shop indian_restaurant indonesian_restaurant italian_restaurant japanese_restaurant korean_restaurant lebanese_restaurant meal_delivery meal_takeaway mediterranean_restaurant mexican_restaurant middle_eastern_restaurant pizza_restaurant ramen_restaurant restaurant sandwich_shop seafood_restaurant spanish_restaurant steak_house sushi_restaurant thai_restaurant turkish_restaurant vegan_restaurant vegetarian_restaurant vietnamese_restaurant grocery_store market supermarket'
        : 'Groceries',
        'park amusement_park dog_park hiking_area historical_landmark national_park tourist_attraction wedding_venue zoo'
        : 'Parks',
        'dental_clinic dentist doctor drugstore hospital'
        : 'Medical care',
        'library preschool primary_school school secondary_school university': 'Schools',
        'art_gallery museum performing_arts_theater amusement_center aquarium banquet_hall bowling_alley casino community_center convention_center cultural_center marina movie_rental movie_theater night_club tourist_attraction visitor_center'
        : 'Entertainment',
        'farm car_dealer car_rental car_repair car_wash electric_vehicle_charging_station gas_station parking rest_stop accounting atm bank city_hall courthouse embassy local_government_office police post_office church hindu_temple mosque synagogue athletic_field fitness_center golf_course gym playground ski_resort sports_club sports_complex stadium swimming_pool'
        : 'Other',
    };


    useEffect(() => {
        if (options[value] && selectedItem !== options[value]) {
            setSelectedItem(options[value]);
        } else if (!options[value] && selectedItem !== options['all']) {
            setSelectedItem(options['all']);
        }
    }, [value]);


    const onToggleDropdown = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setDropdownVisible(!dropdownVisible);
    }


    const onItemSelect = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let optionSelected = Object.keys(options).find(key => options[key] === e.target.text);
        if (!optionSelected) {
            optionSelected = 'all';
        }
        setSelectedItem(optionSelected);
        setDropdownVisible(false);

        if (onChange) {
            onChange(optionSelected);
        }
    }


    return (
        <div className="search-bar-type-selector">
            <div className="item-selected" onClick={onToggleDropdown}>
                <div>{options[selectedItem] ? options[selectedItem] : options['all']}</div>
                <IconChevronDown size={15} />
            </div>

            <div className={'search-bar-dropdown ' + (dropdownVisible ? 'show' : '')}>
                {Object.keys(options).map((key) => {
                    return (
                        <a key={key} className="dropdown-item" href="#" onClick={onItemSelect}>{options[key]}</a>
                    );
                })}
            </div>
        </div>
    );
}