import React, { useEffect } from "react";
import SearchBarTypeSelector from "./SearchBarTypeSelector";
import { useMap } from "../../../providers/MapProvider";
import { useGoogleMapsApi } from "../../../providers/googleMapsApiProvider";

export default function SearchBar({ value = '', onChange }) {
    const radiusNearby = 5000;
    
    const googleMapsApiContext = useGoogleMapsApi();
    const mapContext = useMap();

    const [searchValue, setSearchValue] = React.useState(value);
    const [placeInfo, setPlaceInfo] = React.useState(null);
    const [selectedType, setSelectedType] = React.useState('all');
    const [locationCurrent, setLocationCurrent] = React.useState(null);

    const autocompleteInput = React.createRef();
    const autocomplete = React.createRef();
    const places = React.createRef();


    useEffect(() => {
        if (googleMapsApiContext.gmAPILoaded) {
            let autocompleteOptions = {
                //componentRestrictions: {country: 'es'},
            };

            autocomplete.current = new window.google.maps.places.Autocomplete(
                autocompleteInput.current,
                autocompleteOptions
            );
            places.current = new window.google.maps.places.PlacesService(googleMapsApiContext.gmMap);

            autocomplete.current.addListener('place_changed', () => {
                const place = autocomplete.current.getPlace();
                if (place.geometry && place.geometry.location) {
                    const placeName = place.name;
                    fetchLocations({
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                        name: placeName,
                    });
                }
            });
        }
    }, [googleMapsApiContext.gmAPILoaded]);

    useEffect(() => {
        if (locationCurrent != null) {
            fetchLocations(locationCurrent);
        }
    }, [selectedType]);


    useEffect(() => {
        setSearchValue(value);
    }, [value]);

    const fetchLocations = (location) => {
        let tmpSelectedType = document.querySelector('.search-bar-type-selector .item-selected div').innerText;
            const options = {
                'all'
                : 'All types',
                'american_restaurant bakery bar barbecue_restaurant brazilian_restaurant breakfast_restaurant'
                : 'Groceries',
                'park amusement_park dog_park hiking_area historical_landmark national_park tourist_attraction wedding_venue zoo'
                : 'Parks',
                'dental_clinic dentist doctor drugstore hospital'
                : 'Medical care',
                'library preschool primary_school school secondary_school university': 'Schools',
                'art_gallery museum performing_arts_theater amusement_center aquarium banquet_hall bowling_alley casino community_center convention_center cultural_center marina movie_rental movie_theater night_club tourist_attraction visitor_center'
                : 'Entertainment',
                'farm car_dealer car_rental car_repair car_wash electric_vehicle_charging_station gas_station parking rest_stop accounting atm bank city_hall courthouse embassy local_government_office police post_office church hindu_temple mosque synagogue athletic_field fitness_center golf_course gym playground ski_resort sports_club sports_complex stadium swimming_pool'
                : 'Other'
                };
        //     'american_restaurant bakery bar barbecue_restaurant brazilian_restaurant breakfast_restaurant brunch_restaurant cafe chinese_restaurant coffee_shop fast_food_restaurant french_restaurant greek_restaurant hamburger_restaurant ice_cream_shop indian_restaurant indonesian_restaurant italian_restaurant japanese_restaurant korean_restaurant lebanese_restaurant meal_delivery meal_takeaway mediterranean_restaurant mexican_restaurant middle_eastern_restaurant pizza_restaurant ramen_restaurant restaurant sandwich_shop seafood_restaurant spanish_restaurant steak_house sushi_restaurant thai_restaurant turkish_restaurant vegan_restaurant vegetarian_restaurant vietnamese_restaurant grocery_store market supermarket'
        //     : 'Groceries',
            let tmpSelectedTypeKey = Object.keys(options).find(key => options[key] === tmpSelectedType);
        
        if (tmpSelectedTypeKey === 'all') {
            tmpSelectedTypeKey = Object.keys(options).slice(1).join(' ');
        }

        setLocationCurrent(location);

        let search = {
            fields: ["displayName", "location", "businessStatus"],
            locationRestriction: {
                center: location,
                radius: radiusNearby,
            },
        };

        let placesColleted = [];
        window.google.maps.importLibrary(
            "places",
        ).then((results) => {
            const { Place, SearchNearbyRankPreference } = results;

            for (const selectType of tmpSelectedTypeKey.split(" ")) {
                search.includedPrimaryTypes = [selectType];
                // console.log(search.includedPrimaryTypes);
                placesColleted.push(Place.searchNearby(search)); 
            };
            Promise.all(placesColleted).then(( places ) => {
                let allPlaces = [];
                for (let i=0; i<places.length; i++) {
                    allPlaces = [...allPlaces, ...places[i].places];
                }
                // console.log(allPlaces);
                onChange({
                    locations: allPlaces,
                    center: location,
                });
            });
        });
    }


    return (
        <div className="search-bar">
            <input
                type="text"
                className={"form-control main-search"}
                placeholder="Search..."
                //value={searchValue}
                //onChange={(e) => {setSearchValue(e.target.value)}}
                ref={autocompleteInput}
            />

            <SearchBarTypeSelector
                value={''}
                onChange={(value) => { setSelectedType(value); }}
            />
        </div>
    );
}