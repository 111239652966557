import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";
import Map from "./../map/general/Map";

export default function Home(props) {
    const apiContext = useApi();


    useEffect(() => {
        document.title = 'Welcome | Map';
    }, []);


    return (
        <Map />
    );
}