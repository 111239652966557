import BaseCrudModel from "./BaseCrudModel";

/**
 * Projects model
 *
 * @param {ApiHelper} api
 */
export default class ShapesModel extends BaseCrudModel {
    baseUrl = '/shapes';

    constructor(api) {
        super(api);
    }
}