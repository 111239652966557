import React, { Component, useCallback, useEffect, useRef } from 'react';
import _ from 'lodash';

import * as L from "leaflet";
import 'leaflet.heat';

import MapHelper from "./../../../helpers/MapHelper";

import { useMap } from "../../../providers/MapProvider";
import { useApi } from "../../../providers/ApiProvider";
import ShapesModel from "../../../models/ShapesModel";
import { useNotifications } from "../../../providers/NotificationsProvider";
import SearchBar from "../ui/SearchBar";

import imgIcon from "../../../assets/img/flag.svg";


export default function Map({ }) {
    // const markerIcon = null; //  marker default
    // const markerIcon = L.divIcon({ className: 'leaflet-marker-icon marker-icon leaflet-zoom-animated leaflet-interactive' });   //  marker as a ring
    const iconSize = [50, 82];  // 25x41 - standard size
    const iconAnchor = [25, 82];

    const showMarkers = true;
    const showHeatLayer = true;

    // Heat Layer transition intensity
    const transitionIntensity = 1;  //  transition intensity  0 - 1
    const radius = 45;
    const blur = 50;
    const maxZoom = 15;
    const maxOpacity = 0.001;
    const gradientStart = 0.1;
    const gradientStartColor = 'blue';
    const gradientMed = 0.5;
    const gradientMedColor = 'lime';
    const gradientEnd = 1.0;
    const gradientEndColor = 'red';


    const mapContext = useMap();
    const apiContext = useApi();
    const shapesModel = new ShapesModel(apiContext.api);
    const notifications = useNotifications();

    const mapRef = React.useRef(null);
    const [map, setMap] = React.useState(null);
    const [mapLayers, setMapLayers] = React.useState([]);

    const searchMarkers = useRef([]);
    const searchMarkerCenter = useRef(null);
    const searchHeatLayer = useRef(null);


    useEffect(() => {
        if (!mapContext.isInitialized) {
            const mapElements = MapHelper.initMap(mapRef.current);
            setMapLayers(mapElements.layers);

            mapContext.init(mapElements.map);
        }
        if (!mapRef.current) return;
    }, []);


    useEffect(() => {
        if (mapContext.isInitialized) {
            setMap(mapContext.map);
            if (mapLayers['google']) {
                mapLayers['google'].addTo(mapContext.map);
            }
        }
    }, [mapContext.isInitialized]);


    const IconMarkerCenter = new L.Icon({
        iconUrl: imgIcon,
        iconSize: iconSize,
        iconAnchor: iconAnchor,
    });


    const onSearchChange = (info) => {
        let markers;
        let markerCenter;
        let heatData;

        if (searchMarkers.current.length) {
            searchMarkers.current.forEach((marker) => map.removeLayer(marker));
            searchMarkers.current = [];

            map.removeLayer(searchMarkerCenter.current);
            map.removeLayer(searchHeatLayer.current);
            searchMarkerCenter.current = null;
        };

        markerCenter = L.marker([info.center.lat, info.center.lng], { icon: IconMarkerCenter });
        markerCenter.bindPopup(`<div>${info.center.name}</div>`);

        if (info.locations) {
            markers = info.locations.map((location) => {
                let marker = L.marker([location.location.lat(), location.location.lng()]);
                // let marker = L.marker([location.location.lat(), location.location.lng()], { icon: markerIcon });
                const popupContent = `<div>${location.displayName}</div>`;
                marker.bindPopup(popupContent);

                return marker;
            });
        }

        searchMarkers.current = markers;
        searchMarkerCenter.current = markerCenter;

        if (showMarkers) {
            markers.forEach((marker) => {
                marker.addTo(mapContext.map);
            });
        }

        if (showHeatLayer) {
            heatData = markers.map(marker => {
                const lat = marker.getLatLng().lat;
                const lng = marker.getLatLng().lng;
                return [lat, lng, transitionIntensity];

            });

            searchHeatLayer.current = L.heatLayer(heatData, {
                radius: radius,
                blur: blur,
                maxZoom: maxZoom,
                maxOpacity: maxOpacity,
                gradient: {
                    [gradientStart]: gradientStartColor,
                    [gradientMed]: gradientMedColor,
                    [gradientEnd]: gradientEndColor
                }
            }).addTo(mapContext.map);
        }

        markerCenter.addTo(mapContext.map);
        mapContext.map.setView(info.center, maxZoom);
    }

    return (
        <div className="map-panel">
            <div id="map-container" ref={mapRef}></div>

            <div className={'map-area map-top'}>
                <SearchBar
                    onChange={onSearchChange}
                />
            </div>
        </div>
    );
}