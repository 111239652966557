import * as L from "leaflet";
import "leaflet.gridlayer.googlemutant";

export default class MapHelper {
    static defaultCenter = L.latLng(37.6174439,-1.009107);
    static defaultZoomLevel = 15;
    static defaultZoomLevelDetailed = 17;
    static defaultMaxZoom = 24;
    static defaultMapType = 'google';


    static createGoogleTilesLayer() {
        return L.gridLayer.googleMutant({
            type: "hybrid", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
            maxZoom: MapHelper.defaultMaxZoom
        });
    }


    static initMap(htmlContainer, position = null, zoom = null) {
        let map = L.map(htmlContainer, {
            maxZoom: MapHelper.defaultMaxZoom,
        });


        let googleLayer = MapHelper.createGoogleTilesLayer();


        if (!position) {
            position = MapHelper.defaultCenter;
        }
        if (!zoom) {
            zoom = MapHelper.defaultZoomLevel;
        }
        map.setView(position, zoom);


        return {
            map: map,
            layers: {
                google: googleLayer,
            }
        };
    }
}