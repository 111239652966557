import React from 'react';
import ReactDOM from 'react-dom/client';

import 'react-toastify/dist/ReactToastify.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'leaflet/dist/leaflet.css';
import "./assets/css/base/theme.css";
import "./assets/css/styles/merged.css";


import reportWebVitals from './reportWebVitals';


import {RouterProvider} from "react-router-dom";
import {ApiProvider} from "./providers/ApiProvider";
import {NotificationsProvider} from "./providers/NotificationsProvider";
import {router} from "./router";
import {GoogleMapsApiProvider} from "./providers/googleMapsApiProvider";
import * as L from "leaflet";

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {MapProvider} from "./providers/MapProvider";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [13, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ApiProvider>
        <GoogleMapsApiProvider>
            <NotificationsProvider>
                <MapProvider>
                    <RouterProvider router={router} />
                </MapProvider>
            </NotificationsProvider>
        </GoogleMapsApiProvider>
    </ApiProvider>
);

reportWebVitals();