import * as React from "react";

const googleMapsApiStructure = {
    gmAPILoaded: false,
    gmMap: null,
    update: () => {}
}

const GoogleMapsApiContext = React.createContext(googleMapsApiStructure);


function GoogleMapsApiProvider({children}) {
    let [googleMapsApi, setGoogleMapsApi] = React.useState(googleMapsApiStructure);

    const updateGoogleMapsAPI = (apiLoaded) => {
        let newGoogleMapsApi = {...googleMapsApi};
        newGoogleMapsApi.gmAPILoaded = apiLoaded;
        newGoogleMapsApi.gmMap = new window.google.maps.Map(document.getElementById("gm-map"), {
            center: {lat: 0, lng: 0},
            zoom: 10,
        });

        setGoogleMapsApi(newGoogleMapsApi);
    };

    let value = {...googleMapsApi};
    value.update = updateGoogleMapsAPI;

    return <GoogleMapsApiContext.Provider value={value}>{children}</GoogleMapsApiContext.Provider>;
}


function useGoogleMapsApi() {
    return React.useContext(GoogleMapsApiContext);
}


export {GoogleMapsApiProvider, useGoogleMapsApi};