import React, {useEffect} from 'react';
import {Outlet} from "react-router-dom";

import 'leaflet/dist/leaflet.css';

import {ToastContainer} from "react-toastify";
import {useGoogleMapsApi} from "./providers/googleMapsApiProvider";

export default function App(props) {
    const googleMapsApiContext = useGoogleMapsApi();
    const [preloaderActive, setPreloaderActive] = React.useState(true);

    useEffect(() => {
        setPreloaderActive(false);

        includeGoogleMapsAPI();
    }, []);


    const includeGoogleMapsAPI = () => {
        const script = document.createElement("script");

        const params = {
            v: '3.exp',
            libraries: 'places',
            callback: 'googleMapsAPILoadedCallback',
            key: process.env.REACT_APP_GOOGLEMAPS_TOKEN
        };
        let paramsArr = [];
        for (const [key, value] of Object.entries(params)) {paramsArr.push(key+'='+value);}
        script.src = "https://maps.googleapis.com/maps/api/js?"+paramsArr.join('&');
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);

        if (window.googleMapsLoaded) {
            googleMapsApiContext.update(true);
        } else {
            window.addEventListener('google-maps-loaded', () => {
                googleMapsApiContext.update(true);
            });
        }
    }


    return (<>
        <Outlet/>
        <ToastContainer />
        <div id="gm-map"></div>
    </>);
}
