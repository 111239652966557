import {createBrowserRouter} from "react-router-dom";
import App from "./App";
import Home from "./components/common/Home";
import React from "react";
import Error404 from "./components/errors/Error404";

export const router = createBrowserRouter([
    {
        path: "/",
        Component: App,
        children: [
            {
                index: true,
                element: (<Home/>),
            }
        ],
    },
    {
        path: "*",
        Component: Error404,
    }
]);
